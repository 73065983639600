import axios from "axios/index";
import { baseAddress } from "../../../tools/baseAddress";
import FileSaver from "file-saver";
let addressAnalytics = baseAddress + "analytics";
export const getAnalytics = (startTime, endTime) => {
  var formData = new FormData();
  formData.append("start", startTime);
  formData.append("end", endTime);
  formData.append("token", localStorage.getItem("token"))
  
  return axios
    .post(addressAnalytics, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      const blobConfig = new Blob([response.data.config], { type: 'text/csv' });
      FileSaver.saveAs(blobConfig, 'config.csv');
      
      const blobSimulation = new Blob([response.data.simulation], { type: 'text/csv' });
      FileSaver.saveAs(blobSimulation, 'simulation.csv');
      return response;
    })
    .catch(err => {
      if (err.response === undefined || err.status === 500 || "500" in err.response)
        return { "data": "Internal server error! Please try again." };
      return err.response
    })
}