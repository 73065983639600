import React from "react";
import { Grid, Typography, TextField, Button, Container } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { getAnalytics } from "../../analytics/controller/AnalyticsAction";

class AnalyticsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDateValue: null,
            endDateValue: null,
            toggleLoadingScreenDisplay: false
        };
        this.requestAnalyticsData = this.requestAnalyticsData.bind(this);
        this.requestClose = this.requestClose.bind(this);
    }

    requestAnalyticsData = async () => {
        this.setState({ toggleLoadingScreenDisplay: true });
        await getAnalytics(this.state.startDateValue, this.state.endDateValue);
        this.setState({ toggleLoadingScreenDisplay: false });
    }

    requestClose = () => {
        this.props.onCloseRequested();
    }

    render() {
        return (
            <Container>
                <Grid container direction="row" style={{ padding: "20px" }} xs={12}>
                    <Typography variant="h5" style={{ marginBottom: "20px" }}>Export simulation data</Typography>
                    <Typography variant="body1" style={{ marginBottom: "20px" }}>Select the date range for the simulation data you would like to export</Typography>
                    <Grid container direction="row" style={{ marginBottom: "20px" }} spacing={2}>
                        <Grid item xs={12} sm={5}>
                            <TextField
                                id="startDate"
                                label="Start Date"
                                type="date"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => this.setState({ startDateValue: event.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="body1">to</Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <TextField
                                id="endDate"
                                label="End Date"
                                type="date"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => this.setState({ endDateValue: event.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={2} style={{ marginTop: 20 }}>
                        <Grid item>
                            <Button
                                style={{ marginRight: 14 }}
                                onClick={this.requestAnalyticsData}
                                disabled={this.state.startDateValue === null || this.state.endDateValue === null}
                                variant="contained"
                                color="primary"
                            >
                                Export
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={this.requestClose}>Close</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withRouter(AnalyticsContainer);