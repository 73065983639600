import axios from "axios"
import {baseAddress} from "../tools/baseAddress"
let address = baseAddress+"users/login";

const generateSessionId = () => {
  const timestamp = Date.now().toString().slice(-5); // Last 5 digits of the timestamp
  const randomNum = Math.floor(Math.random() * 100000).toString().padStart(5, '0'); // Random 5-digit number
  const sessionIndex = timestamp + randomNum;
  console.log("Session ID: " + sessionIndex);
  return sessionIndex;
}

export const login = user => {
  return axios
    .post(address, {
      email: user.email,
      password: user.password
    })
    .then(response => {
      if(response.data.token !== undefined)
        localStorage.setItem('token',response.data.token);
      localStorage.setItem('session_id', generateSessionId());
      return response.data
    })
    .catch(err => {
      console.log(err)
    })
}