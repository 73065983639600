import React from "react";
import { withRouter } from 'react-router-dom';
import { Grid, Card, CardHeader, Box, Container, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import { generate_img, exportPDF } from "../controller/AcousticAction";
import Avatar from '@material-ui/core/Avatar';
import CardActions from '@material-ui/core/CardActions';
import applicationLogo from '../../../design/zeiss-logo-tagline_rgb.png';
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Feedback } from "@material-ui/icons";
import { getPeakDistortionsInterface } from "../../tools/OutputTools";
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({

    inpage: {
        width: "1024px",
        margin: "auto",
    },
    margin: {
        margin: theme.spacing(1),
    },
    slider: {
        margin: theme.spacing(3),
        // width: "60%",
        height: 8,
    },
    root: {
        flexGrow: 1,
    },
    media: {
        width: '1024px',
        height: 0,
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    large: {

        backgroundColor: 'white',
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
});

function valuetext(value) {
    return `${value}`;
}

const Magnification = withStyles({
    root: {
        // color: '#464a47',
        height: 6,
    },
    thumb: {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

class VerticalSlider extends React.Component {
    constructor(props) {
        super(props);
        this.refreshImage = this.refreshImage.bind(this);
        this.goBacktoInput = this.goBacktoInput.bind(this);
        this.exportFile = this.exportFile.bind(this);

        this.state = {
            min_value: 0,
            max_value: 0,
            marks: [],
            pic: "",
            picWithoutDistortion: "",
            roomEffect: true,
            value: 0,
            index: 4,
            input_data: {},
            response_data: {},
            selectOptionsEnergy: [],
            selectOptionsDistance: [],
            beamEnergy: "",
            workingDistance: "",
            overhead: "",
            frequency: "",
            acoustic_level: 0,
            loadingScreen: false,
            customer_name: "",
            refreshingImage: false,
            distortionMethod: "Measured",
            inputTXTFile: {}
        };
    }

    componentDidMount() {
        // Load option beam energy
        let dataInput = JSON.parse(localStorage.getItem("dataInput"))
        this.setState({distortionMethod:dataInput["distortionMethod"], inputTXTFile:dataInput["inputTXTFile"]})
        let customer_name = dataInput["customer_name"];
        let dataList = []
        dataInput.dataList.forEach(element => {
            if (element["frequency"] !== "" && element["distortion_value"] !== "")
                dataList.push(element)
        });
        dataInput.dataList = dataList;
        dataInput["session_id"] = localStorage.getItem("session_id");
        generate_img(dataInput).then(({ data: response_url }) => {
            if ('redirect' in response_url) {
                this.props.history.push('/login')
            }

            this.setState({
                pic: response_url["imageBytesDistortion"],
                picWithoutDistortion: response_url["imageBytesWithoutDistortion"],
                frequency: response_url["frequency"],
                acoustic_level: response_url["acoustic_level"],
                marks: response_url["steps"],
                max_value: response_url["steps"][response_url["steps"].length - 1].value,
                min_value: response_url["steps"][0].value,
                input_data: dataInput,
                response_data: response_url,
                overhead: response_url["overhead"],
                customer_name: customer_name,
                value: 0
            });
        });
    }

    updateValue = (event, newValue) => {
        if (newValue !== this.state.value) {
            this.setState({ value: newValue });
            let dataInput = this.state.input_data;
            let magnification = parseFloat(this.state.response_data['steps'][newValue]['label'].slice(0, -1)) * 1000
            dataInput.magnification = magnification;
            this.setState({ input_data: dataInput });
        }
    };

    refreshImage = async () => {

        // Update local storage
        let dataInputCopy = JSON.parse(localStorage.getItem("dataInput"));
        const json = JSON.stringify(dataInputCopy);
        localStorage.setItem("dataInput", json);

        await this.setState({ refreshingImage: true })
        let dataInput = this.state.input_data;
        dataInput["overhead"] = this.state.overhead;
        dataInput["session_id"] = localStorage.getItem("session_id");
        generate_img(dataInput).then(({ data: response_url }) => {
            if ('redirect' in response_url) {
                this.props.history.push('/login')
            }
            this.setState({
                frequency: response_url["frequency"],
                acoustic_level: response_url["acoustic_level"],
                pic: response_url["imageBytesDistortion"],
                picWithoutDistortion: response_url["imageBytesWithoutDistortion"],
                response_data: response_url,
                refreshingImage: false
            });
        });
    }
    handleDistortionSwitch = (event) => {
        this.setState({ roomEffect: event.target.checked });
    };

    handleDistortionMethod = (event) => {
        let dataInput = this.state.input_data;
        dataInput["distortionMethod"] = event.target.value
        this.setState({ distortionMethod: event.target.value,
                        input_data: dataInput});
    };

    goBacktoInput() {
        // this.props.history.push('/input')
        this.props.history.push({
            pathname: '/inputs',
            state: { goBack: "yes" }
        })
    }

    exportFile() {
        this.setState({ loadingScreen: true })
        let dataInput = Object.assign({},this.state.input_data);
        dataInput.overhead = this.state.overhead;
        dataInput.magnification =Object.assign({}, this.state.response_data)["magnification"] + "k";
        dataInput.imageSize = "1024px X 1024px"
        dataInput.pixelSize = this.state.response_data["pixel_size"]
        //        dataInput.distortion = this.state.response_data["distortion"] + " nm"
        dataInput.angle = this.state.response_data["angle"] === 90 ? "Y Axis" : "X Axis";
        dataInput.ImageBytes = this.state.pic
        dataInput.frequency = this.state.frequency
        dataInput.acoustic_level = this.state.acoustic_level

        exportPDF(dataInput).then(({ data: response_url }) => {
            this.setState({ loadingScreen: false })
            if (response_url === Object && 'redirect' in response_url) {
                this.props.history.push('/login')
            } else {
                let link = document.createElement('a');
                const mimeType = "application/pdf";
                link.href = `data:${mimeType};base64,${response_url["data"]}`;
                link.download = response_url["download_name"];
                link.click();
            }
        });

    }

    render() {
        const { classes } = this.props;
        const theme = {
            fontSize: 12.5,
        };

        return <>
            {this.state.pic === "" || this.state.loadingScreen === true ? <div
                style={{
                    top: "50%",
                    left: "50%",
                    marginTop: "-50px",
                    marginLeft: "-100px",
                    position: "absolute",
                    width:"200px",
                    height:"200px"
                }}
              >
                
                <img alt="App logo" src={applicationLogo} style={{
                    width: "80%",
                    height:"80%",
                    position:"absolute",
                    left:"10%",
                    top:"5%"
                }} />

                    <CircularProgress style={{
                        position: "absolute",
                        width:"100%",
                        height:"100%"
                    }} />

            </div> :
                <Container>
                    <Grid className="page_header" style={{ marginBottom: 20 }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CardHeader
                                    avatar={
                                        <Avatar variant={"square"} aria-label="ripple-simulator"
                                            src={applicationLogo}
                                            className={classes.large} />
                                    }
                                    titleTypographyProps={{ variant: "h4", color: 'textPrimary' }}
                                    title={"Ripple Simulator - Acoustic distortion simulation"}
                                    subheaderTypographyProps={{ variant: "h5", color: 'textSecondary' }}
                                    subheader={this.state.customer_name !== undefined ? "Customer name: " + this.state.customer_name : null}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" spacing={1} justifyContent="center" alignItems="center">

                        <Grid item xs={12} style={{ paddingBottom: 10 }}>
                            <Grid container direction="row">
                                <Grid item md={3} xs={6}>
                                    <Typography style={theme}><b>Input parameters</b></Typography>
                                    <Typography style={theme}>SYSTEM PARAMETERS:</Typography>
                                    <Typography style={theme}>System
                                        name: {this.state.input_data["system"]}</Typography>
                                    <Typography
                                        style={theme}>Magnification: {this.state.response_data["magnification"] + "k"}</Typography>
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <Typography style={theme}>SIMULATION INPUT PARAMETERS USED:</Typography>
                                    <Typography style={theme}>Frequency: {!isNaN(this.state.frequency) === true? this.state.frequency + " Hz": this.state.frequency} </Typography>
                                    <Typography style={theme}>Acoustic
                                        level: {!isNaN(this.state.acoustic_level) === true? parseFloat(this.state.acoustic_level).toFixed(2) + " dB" : this.state.acoustic_level}</Typography>
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <Typography style={theme}><b>Image details</b></Typography>
                                    <Typography style={theme}>Image size: 1024px X 1024px</Typography>
                                    <Typography style={theme}>Frame
                                        size: {this.state.response_data["pixel_size"] * 1024} nm</Typography>
                                    <Typography style={theme}>Image pixel
                                        size: {this.state.response_data["pixel_size"]} nm</Typography>
                                    <Typography style={theme}>Distortion
                                        direction: {this.state.response_data["angle"] === 90 ? "Y Axis" : "X Axis"}</Typography>
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <Typography style={theme}><b>Note!</b></Typography>
                                    <Typography style={theme}>The image is a digitally altered simulation with the ripple in only X or Y axis, 
                                    depending on which has the highest value. Z distortion is always represented in X axis. 
                                    Approximately 1nm of ripple in the image is acceptable for most applications. 
                                    The standard digital filtering will help to improve the image.</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} spacing={1} container direction="row">
                            <Grid item md={10} xs={12}>
                            <img width="100%" height="auto" alt="Test" src={`data:image/jpeg;base64,${this.state.roomEffect === true ? this.state.pic : this.state.picWithoutDistortion}`} />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <Grid container direction="column" justifyContent="space-between" style={{ height: "100%", padding: 4 }}>
                                    <Grid>
                                        <Box overflow="auto">
                                            <Typography style={{fontWeight: "bold", fontSize: "12.5px"}}>ROOM DETAILS</Typography>
                                            {getPeakDistortionsInterface(this.state.input_data["dataList"], "acoustic")}
                                            <Typography style={{ fontWeight: "bold", marginTop: "25px", fontSize: "12.5px" }}>SYSTEM SENSITIVITY</Typography>
                                            <FormControlLabel
                                                style={{marginLeft:1}}
                                                labelPlacement="start"
                                                control={
                                                    <Switch
                                                        checked={this.state.distortionMethod === "Measured"? true:false}
                                                        color="primary"
                                                        value="Measured"
                                                        onChange={this.handleDistortionMethod}/>}
                                                label={<Typography style={{fontSize:"12px", width:"80px"}}>Measured</Typography>}
                                            />
                                            <FormControlLabel
                                                style={{marginLeft:1}}
                                                labelPlacement="start"
                                                control={
                                                    <Switch
                                                        checked={this.state.distortionMethod === "Resonant"? true:false}
                                                        color="primary"
                                                        value="Resonant"
                                                        disabled ={ Object.keys(this.state.inputTXTFile).length === 0?true:false}
                                                        onChange={this.handleDistortionMethod}/>}
                                                label={<Typography style={{fontSize:"12px", width:"80px"}}>Resonant</Typography>}
                                            />
                                            <FormControlLabel
                                                style={{marginLeft:1}}
                                                labelPlacement="start"
                                                control={
                                                    <Switch
                                                        checked={this.state.distortionMethod === "Worst case"? true:false}
                                                        color="primary"
                                                        value="Worst case"
                                                        onChange={this.handleDistortionMethod}/>}
                                                label={<Typography style={{fontSize:"12px", width:"78px"}}>Worst case</Typography>}
                                            />
                                            <Typography style={{ fontWeight: "bold", marginTop: "25px", fontSize: "12.5px" }}>ROOM DETAILS</Typography>
                                            <FormControlLabel
                                                    style={{marginLeft:1}}
                                                    labelPlacement="start"
                                                    control={
                                                        <Switch
                                                            onChange={this.handleDistortionSwitch}
                                                            color="primary"
                                                            checked={this.state.roomEffect}
                                                        />
                                                    }
                                                    label={<Typography style={{fontSize:"12px", width:"78px", textAlign:"justify"}}>Simulate room effect</Typography>}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item >
                                        <Button fullWidth={true} variant="contained" size="small"
                                            onClick={this.goBacktoInput}
                                            style={{ marginBottom: 20, lineHeight: "normal" }}>
                                            <ArrowBackIcon style={{ marginRight: 5, height: "30px" }} />Back to
                                            settings
                                        </Button>
                                        <Button fullWidth={true} size="small" variant="contained"
                                            onClick={this.exportFile} style={{ lineHeight: "normal" }}>
                                            <SaveIcon style={{ marginRight: 10, height: "30px" }} /> Export
                                            simulation
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} spacing={1} container>
                            <Typography style={{ padding: "23px 8px 0px 0" }}><b>Magnification (Polaroid
                                4"X5") </b></Typography>
                        </Grid>
                        <Grid item xs={12} spacing={1} container>

                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={10} md={10} xl={10}>

                                    <Card className={classes.r343oot} fullwidth="true"
                                        style={{ width: "100%", marginRight: "50px" }}>
                                        {/*<CardMedia
                                                className={classes.media}
                                                image={this.state.pic}
                                                title="Test Image RS with 10.000x magnification"
                                            />*/}
                                        <CardActions fullwidth="true" className={classes.inpage}
                                            style={{ width: "100%", paddingTop: 0 }}>
                                            <Magnification style={{ marginTop: 5 }}
                                                className={classes.slider}
                                                id={"magnification-slider"}
                                                value={this.state.value}
                                                step={null}
                                                min={this.state.min_value}
                                                max={this.state.max_value}
                                                aria-valuemin={10000}
                                                aria-valuemax={500000}
                                                track={"normal"}
                                                marks={this.state.marks}
                                                aria-labelledby="vertical-slider"
                                                getAriaValueText={valuetext}
                                                onChange={this.updateValue}
                                            />
                                        </CardActions>
                                    </Card>

                                </Grid>
                                <Grid style={{ height: "100%" }} item xs={2} md={2} xl={2}>
                                    <Button variant="contained" color="primary" size="small"
                                        style={{ height: "100%", width: "95%", lineHeight: "normal", marginLeft: 8 }}
                                        onClick={this.refreshImage}>
                                        <SyncIcon style={{ marginRight: 10 }} className={this.state.refreshingImage === true ? "icon-spin" : null} />Apply change</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{ height: "100px" }} />
                    </Grid>

                    {/*<div className="footer">
                            <div className="page">
                                ZEISS International, &copy; 2021
                            </div>
                        </div>*/}
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Button style={{ position: "fixed", bottom: "0", right: "0", marginRight: "2%", marginBottom: "2%", width: "150px" }}
                            href="mailto:techsupport.sem.microscopy@zeiss.com?subject=Ripple%20Simulator%20Feedback&body=Hello!%0D%0AI%20have%20some%20feedback%20about%20the%20app.%0D%0A"
                            variant="contained" fullWidth={true} color="primary" size={"large"}>
                            <Feedback fontSize="large" style={{ marginRight: "5px" }} />
                            Feedback
                        </Button>
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <Button style={{ position: "fixed", bottom: "0", right: "0", marginRight: "2%", marginBottom: "2%", width: "50px" }}
                            href="mailto:techsupport.sem.microscopy@zeiss.com?subject=Ripple%20Simulator%20Feedback&body=Hello!%0D%0AI%20have%20some%20feedback%20about%20the%20app.%0D%0A"
                            variant="contained" fullWidth={true} color="primary" size={"large"}>
                            <Feedback fontSize="large" style={{ marginRight: "5px" }} />
                        </Button>
                    </Box>
                </Container>}
        </>;
    }
}

export default withRouter(withStyles(styles)(VerticalSlider));